<template>
  <div class="flex flex-row items-center w-full py-5 ">
    <div class="flex justify-center w-1/2 ml-5 ">
      <span class="px-5 ml-2 text-2xl">
        {{ title }}
      </span>
    </div>
    <div class="flex justify-center w-1/2">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    title() {}
  }
});
</script>
