<template>
  <LoadingBar v-if="isLoading"></LoadingBar>
  <loading :active="isLoadingA" :is-full-page="true" :loader="'bars'" />
  <div class="flex flex-col h-full">
    <page-header>
      <page-title title="Customise Queue Screen"> </page-title>
    </page-header>

    <div class="vendor-container">
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        >
          <!-- <div class="px-3 mt-6">
            <h3 class="flex-1 w-full ml-2 text-left text-gray-600 ">
              CUSTOMIZE QUEUE SCREEN
            </h3>
          </div> -->
          <div class="flex flex-col px-3 py-3 mt-5 bg-white sm:rounded-lg">
            <div class="flex items-center border-b-2">
              <div class="flex-col flex-1">
                <p class="flex flex-1 w-full mt-1 ml-2 text-lg text-left">
                  <input
                    name="venueName"
                    type="text"
                    class="
                      flex-1
                      block
                      w-full
                      mt-1
                      ml-2
                      text-lg
                      font-bold
                      border-transparent
                      rounded
                      focus:border-transparent focus:ring-0
                    "
                    v-model="inputs.business_name"
                    :disabled="isDisabled.venueName"
                    @input="fieldVerification('business_name')"
                  />
                  <button
                    v-if="isDisabled.venueName == false"
                    class="
                      cursor-pointer
                      focus:border-transparent focus:ring-0 focus:outline-none
                    "
                    @click="updateVendorName()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 50 50"
                    >
                      <g
                        id="Group_286"
                        data-name="Group 286"
                        transform="translate(-1481 -808)"
                      >
                        <g
                          id="Ellipse_19"
                          data-name="Ellipse 19"
                          transform="translate(1481 808)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        >
                          <circle cx="25" cy="25" r="25" stroke="none" />
                          <circle cx="25" cy="25" r="24" fill="none" />
                        </g>
                        <g
                          id="Group_238"
                          data-name="Group 238"
                          transform="translate(413 100)"
                        >
                          <path
                            id="Path_216"
                            data-name="Path 216"
                            d="M4591.953,932.809h21.555"
                            transform="translate(-3510.181 -200.086)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                          <path
                            id="Path_217"
                            data-name="Path 217"
                            d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                            transform="translate(-3543.586 -161)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </p>
              </div>
              <div class="flex mr-5">
                <p class="px-5 ml-2 text-right text-gray-600">Venue Name</p>
                <button
                  class="focus:outline-none focus:ring-0"
                  @click="enableInput('venueName')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 cursor-pointer"
                    viewBox="0 0 25.016 22.24"
                  >
                    <path
                      id="edit"
                      d="M17.472,14.908l1.39-1.39a.349.349,0,0,1,.6.248V20.08a2.085,2.085,0,0,1-2.085,2.085H2.085A2.085,2.085,0,0,1,0,20.08V4.793A2.085,2.085,0,0,1,2.085,2.708H13.963a.35.35,0,0,1,.248.6l-1.39,1.39a.344.344,0,0,1-.248.1H2.085V20.08H17.372V15.151A.342.342,0,0,1,17.472,14.908Zm6.8-8.764-11.4,11.4-3.926.434A1.794,1.794,0,0,1,6.962,16L7.4,12.076,18.8.671a2.535,2.535,0,0,1,3.592,0l1.876,1.876a2.544,2.544,0,0,1,0,3.6ZM19.983,7.485,17.459,4.962,9.39,13.036l-.317,2.836,2.836-.317ZM22.8,4.024,20.921,2.148a.453.453,0,0,0-.643,0L18.936,3.49l2.523,2.523L22.8,4.671A.463.463,0,0,0,22.8,4.024Z"
                      transform="translate(0 0.075)"
                      fill="#009A74"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <p
              class="text-left pl-7 text-red-600"
              v-if="!validStatus.business_name"
            >
              {{ errors.business_name }}
            </p>
            <div class="flex items-center border-b-2">
              <div class="flex-col flex-1">
                <p class="flex flex-1 w-full mt-1 ml-2 text-lg text-left">
                  <input
                    name="category"
                    type="text"
                    class="
                      flex-1
                      block
                      w-full
                      mt-1
                      ml-2
                      border-transparent
                      rounded
                      focus:border-transparent focus:ring-0
                    "
                    v-model="inputs.trading_as"
                    :disabled="isDisabled.category"
                    @input="fieldVerification('trading_as')"
                  />
                  <button
                    v-if="isDisabled.category == false"
                    class="
                      cursor-pointer
                      focus:border-transparent focus:ring-0 focus:outline-none
                    "
                    @click="updateServiceCategory()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 50 50"
                    >
                      <g
                        id="Group_286"
                        data-name="Group 286"
                        transform="translate(-1481 -808)"
                      >
                        <g
                          id="Ellipse_19"
                          data-name="Ellipse 19"
                          transform="translate(1481 808)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        >
                          <circle cx="25" cy="25" r="25" stroke="none" />
                          <circle cx="25" cy="25" r="24" fill="none" />
                        </g>
                        <g
                          id="Group_238"
                          data-name="Group 238"
                          transform="translate(413 100)"
                        >
                          <path
                            id="Path_216"
                            data-name="Path 216"
                            d="M4591.953,932.809h21.555"
                            transform="translate(-3510.181 -200.086)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                          <path
                            id="Path_217"
                            data-name="Path 217"
                            d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                            transform="translate(-3543.586 -161)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </p>
              </div>
              <div class="flex mr-5">
                <p class="px-5 ml-2 text-right text-gray-600">
                  Service Category
                </p>
                <button
                  class="focus:outline-none focus:ring-0"
                  @click="enableInput('category')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 cursor-pointer"
                    viewBox="0 0 25.016 22.24"
                  >
                    <path
                      id="edit"
                      d="M17.472,14.908l1.39-1.39a.349.349,0,0,1,.6.248V20.08a2.085,2.085,0,0,1-2.085,2.085H2.085A2.085,2.085,0,0,1,0,20.08V4.793A2.085,2.085,0,0,1,2.085,2.708H13.963a.35.35,0,0,1,.248.6l-1.39,1.39a.344.344,0,0,1-.248.1H2.085V20.08H17.372V15.151A.342.342,0,0,1,17.472,14.908Zm6.8-8.764-11.4,11.4-3.926.434A1.794,1.794,0,0,1,6.962,16L7.4,12.076,18.8.671a2.535,2.535,0,0,1,3.592,0l1.876,1.876a2.544,2.544,0,0,1,0,3.6ZM19.983,7.485,17.459,4.962,9.39,13.036l-.317,2.836,2.836-.317ZM22.8,4.024,20.921,2.148a.453.453,0,0,0-.643,0L18.936,3.49l2.523,2.523L22.8,4.671A.463.463,0,0,0,22.8,4.024Z"
                      transform="translate(0 0.075)"
                      fill="#009A74"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <p
              class="text-left pl-7 text-red-600"
              v-if="!validStatus.trading_as"
            >
              {{ errors.trading_as }}
            </p>
            <div class="flex items-center border-b-2">
              <div class="flex-col flex-1">
                <p class="flex flex-1 w-full mt-1 ml-2 text-lg text-left">
                  <input
                    name="category"
                    type="text"
                    class="
                      flex-1
                      block
                      w-full
                      mt-1
                      ml-2
                      border-transparent
                      rounded
                      focus:border-transparent focus:ring-0
                    "
                    v-model="inputs.waiting_message"
                    :disabled="isDisabled.message"
                    @input="fieldVerification('waiting_message')"
                  />
                  <button
                    v-if="isDisabled.message == false"
                    class="
                      cursor-pointer
                      focus:border-transparent focus:ring-0 focus:outline-none
                    "
                    @click="updateMessage()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 50 50"
                    >
                      <g
                        id="Group_286"
                        data-name="Group 286"
                        transform="translate(-1481 -808)"
                      >
                        <g
                          id="Ellipse_19"
                          data-name="Ellipse 19"
                          transform="translate(1481 808)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        >
                          <circle cx="25" cy="25" r="25" stroke="none" />
                          <circle cx="25" cy="25" r="24" fill="none" />
                        </g>
                        <g
                          id="Group_238"
                          data-name="Group 238"
                          transform="translate(413 100)"
                        >
                          <path
                            id="Path_216"
                            data-name="Path 216"
                            d="M4591.953,932.809h21.555"
                            transform="translate(-3510.181 -200.086)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                          <path
                            id="Path_217"
                            data-name="Path 217"
                            d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                            transform="translate(-3543.586 -161)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </p>
              </div>
              <div class="flex mr-5">
                <p class="px-5 ml-2 text-right text-gray-600">
                  Waiting message
                </p>
                <button
                  class="focus:outline-none focus:ring-0"
                  @click="enableInput('message')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 cursor-pointer"
                    viewBox="0 0 25.016 22.24"
                  >
                    <path
                      id="edit"
                      d="M17.472,14.908l1.39-1.39a.349.349,0,0,1,.6.248V20.08a2.085,2.085,0,0,1-2.085,2.085H2.085A2.085,2.085,0,0,1,0,20.08V4.793A2.085,2.085,0,0,1,2.085,2.708H13.963a.35.35,0,0,1,.248.6l-1.39,1.39a.344.344,0,0,1-.248.1H2.085V20.08H17.372V15.151A.342.342,0,0,1,17.472,14.908Zm6.8-8.764-11.4,11.4-3.926.434A1.794,1.794,0,0,1,6.962,16L7.4,12.076,18.8.671a2.535,2.535,0,0,1,3.592,0l1.876,1.876a2.544,2.544,0,0,1,0,3.6ZM19.983,7.485,17.459,4.962,9.39,13.036l-.317,2.836,2.836-.317ZM22.8,4.024,20.921,2.148a.453.453,0,0,0-.643,0L18.936,3.49l2.523,2.523L22.8,4.671A.463.463,0,0,0,22.8,4.024Z"
                      transform="translate(0 0.075)"
                      fill="#009A74"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <p
              class="text-left pl-7 text-red-600"
              v-if="!validStatus.waiting_message"
            >
              {{ errors.waiting_message }}
            </p>

            <div class="flex items-center">
              <div class="flex-col flex-1">
                <p class="flex flex-1 w-full mt-1 ml-2 text-lg text-left">
                  <input
                    name="message"
                    type="text"
                    class="
                      flex-1
                      block
                      w-full
                      mt-1
                      ml-2
                      border-transparent
                      rounded
                      focus:border-transparent focus:ring-0
                    "
                    v-model="inputs.ready_message"
                    :disabled="isDisabled.ready"
                    @input="fieldVerification('ready_message')"
                  />
                  <button
                    v-if="isDisabled.ready == false"
                    class="
                      cursor-pointer
                      focus:border-transparent focus:ring-0 focus:outline-none
                    "
                    @click="updateReadyMessage()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 50 50"
                    >
                      <g
                        id="Group_286"
                        data-name="Group 286"
                        transform="translate(-1481 -808)"
                      >
                        <g
                          id="Ellipse_19"
                          data-name="Ellipse 19"
                          transform="translate(1481 808)"
                          fill="none"
                          stroke="#009A74"
                          stroke-width="2"
                        >
                          <circle cx="25" cy="25" r="25" stroke="none" />
                          <circle cx="25" cy="25" r="24" fill="none" />
                        </g>
                        <g
                          id="Group_238"
                          data-name="Group 238"
                          transform="translate(413 100)"
                        >
                          <path
                            id="Path_216"
                            data-name="Path 216"
                            d="M4591.953,932.809h21.555"
                            transform="translate(-3510.181 -200.086)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                          <path
                            id="Path_217"
                            data-name="Path 217"
                            d="M4638.75,885.242l8.49,8.49-8.49,8.49"
                            transform="translate(-3543.586 -161)"
                            fill="none"
                            stroke="#009A74"
                            stroke-width="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </p>
              </div>
              <div class="flex mr-5">
                <p class="px-5 ml-2 text-right text-gray-600">Ready Message</p>
                <button
                  class="focus:outline-none focus:ring-0"
                  @click="enableInput('ready')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 cursor-pointer"
                    viewBox="0 0 25.016 22.24"
                  >
                    <path
                      id="edit"
                      d="M17.472,14.908l1.39-1.39a.349.349,0,0,1,.6.248V20.08a2.085,2.085,0,0,1-2.085,2.085H2.085A2.085,2.085,0,0,1,0,20.08V4.793A2.085,2.085,0,0,1,2.085,2.708H13.963a.35.35,0,0,1,.248.6l-1.39,1.39a.344.344,0,0,1-.248.1H2.085V20.08H17.372V15.151A.342.342,0,0,1,17.472,14.908Zm6.8-8.764-11.4,11.4-3.926.434A1.794,1.794,0,0,1,6.962,16L7.4,12.076,18.8.671a2.535,2.535,0,0,1,3.592,0l1.876,1.876a2.544,2.544,0,0,1,0,3.6ZM19.983,7.485,17.459,4.962,9.39,13.036l-.317,2.836,2.836-.317ZM22.8,4.024,20.921,2.148a.453.453,0,0,0-.643,0L18.936,3.49l2.523,2.523L22.8,4.671A.463.463,0,0,0,22.8,4.024Z"
                      transform="translate(0 0.075)"
                      fill="#009A74"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <p
              class="text-left pl-7 text-red-600"
              v-if="!validStatus.ready_message"
            >
              {{ errors.ready_message }}
            </p>
          </div>
          <!-- <div class="px-3 mt-6">
            <h3 class="flex-1 w-full ml-2 text-left text-gray-600 ">
              COUNTERS
            </h3>
          </div> -->
          <!-- <div class="flex flex-col px-3 py-3 bg-white sm:rounded-lg">
            <div class="flex items-center">
              <div class="flex-col flex-1">
                <p class="flex-1 block w-full mt-1 ml-2 text-left ">
                  Display Estimated Time
                </p>
              </div>
              <div class="flex">
                <div
                  class="relative inline-block align-middle transition duration-200 ease-in select-none w-14"
                >
                  <input
                    type="checkbox"
                    name="time"
                    id="time"
                    class="toggle-checkbox"
                    :value="queueScreenData.estimatedTime"
                    @input="setQueueScreenData('estimatedTime', $event)"
                  />
                  <label
                    for="time"
                    class="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label"
                  ></label>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="px-3 mt-1">
            <h3 class="flex-1 w-full ml-2 text-left text-gray-600 ">
              Display a countdown timer on the Queue Screen to allow users to
              see when their order is ready for collection.
            </h3>
          </div> -->
        </div>
      </div>
    </div>

    <page-footer>
      <footer-nav></footer-nav>
    </page-footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    Loading,
  },
  data() {
    return {
      isLoadingA: false,
      inputs: {
        business_name: "",
        trading_as: "",
        ready_message: "",
        waiting_message: "",
      },
      blurStatus: {
        business_name: false,
        trading_as: false,
        ready_message: false,
        waiting_message: false,
      },
      errors: {
        business_name: "",
        trading_as: "",
        ready_message: "",
        waiting_message: "",
      },
      validStatus: {
        business_name: true,
        trading_as: true,
        ready_message: true,
        waiting_message: true,
      },
    };
  },
  methods: {
    getQueueScreenData() {
      this.isLoadingA = true;
      this.$http
        .get("console/get-screen")
        .then((response) => {
          this.isLoadingA = false;
          if (response && response.data && response.data.status) {
            this.inputs.business_name = response.data.screen.business_name;
            this.inputs.trading_as = response.data.screen.trading_as;
            this.inputs.waiting_message = response.data.screen.waiting_message;
            this.inputs.ready_message = response.data.screen.ready_message;
          } else {
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    onBlur(field) {
      this.blurStatus[field] = true;
      this.fieldVerification(field);
    },
    fieldVerification(field) {
      if (this.blurStatus[field]) {
        if (this.inputs[field]) {
          if (this.inputs[field].length > 500) {
            this.errors[field] = "Max 500 characters allowed";
            this.validStatus[field] = false;
          } else {
            this.errors[field] = "";
            this.validStatus[field] = true;
          }
        } else {
          this.errors[field] = "This field is required";
          this.validStatus[field] = false;
        }
      }
    },

    updateVendorName() {
      this.onBlur("business_name");
      if (this.validStatus.business_name) {
        this.submitData({ business_name: this.inputs.business_name });
        this.$store.commit("queue/setDisabledVenueName", true);
      }
    },
    updateServiceCategory() {
      this.onBlur("trading_as");
      if (this.validStatus.trading_as) {
        this.submitData({ trading_as: this.inputs.trading_as });
        this.$store.commit("queue/setDisabledCategory", true);
      }
    },
    updateMessage() {
      this.onBlur("waiting_message");
      if (this.validStatus.waiting_message) {
        this.submitData({ waiting_message: this.inputs.waiting_message });
        this.$store.commit("queue/setDisabledMessage", true);
      }
    },
    updateReadyMessage() {
      this.onBlur("ready_message");
      if (this.validStatus.ready_message) {
        this.submitData({ ready_message: this.inputs.ready_message });
        this.$store.commit("queue/setDisabledReadyMessage", true);
      }
    },
    submitData(data) {
      this.isLoadingA = true;
      this.$http
        .post("console/update/screen", data)
        .then((response) => {
          this.isLoadingA = false;
          this.$store.commit(
            "profile/setvendorName",
            this.inputs.business_name
          );
          if (response && response.data && response.data.status) {
            this.$swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "success",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          this.isLoadingA = false;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: error.response.data.message,
          });
        });
    },

    updateQScrrenData() {
      let arr = [
        "business_name",
        "trading_as",
        "ready_message",
        "waiting_message",
      ];
      for (let i = 0; i < arr.length; i++) {
        this.onBlur(arr[i]);
      }
      if (
        this.validStatus.business_name &&
        this.validStatus.trading_as &&
        this.validStatus.ready_message &&
        this.validStatus.waiting_message
      ) {
        this.isLoadingA = true;
        this.$http
          .post("console/update/screen", this.inputs)
          .then((response) => {
            this.isLoadingA = false;
            this.$store.commit(
              "profile/setvendorName",
              this.inputs.business_name
            );
            if (response && response.data && response.data.status) {
              this.$store.commit("queue/setDisabledVenueName", true);
              this.$swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.isLoadingA = false;
            this.$swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "error",
              title: error.response.data.message,
            });
          });
      }
    },
    enableInput(inputType) {
      this.$store.dispatch("queue/setEnableInput", inputType);
    },

    saveInput(val) {
      let updateDetails = {};
      switch (val) {
        case "venue_name": {
          this.$store.commit("queue/setDisabledVenueName", true);
          updateDetails = {
            key: "queue",
            name: "venue_name",
            value: this.$store.state.queue.input.queueScreenData.venueName,
          };
          break;
        }
        case "category": {
          this.$store.commit("queue/setDisabledCategory", true);
          updateDetails = {
            key: "queue",
            name: "category",
            value: this.$store.state.queue.input.queueScreenData.category,
          };
          break;
        }
        case "message": {
          this.$store.commit("queue/setDisabledMessage", true);
          updateDetails = {
            key: "queue",
            name: "message",
            value: this.$store.state.queue.input.queueScreenData.message,
          };
          break;
        }
      }
      this.$store.dispatch("queue/updateQueueData", updateDetails).then(
        (response) => {
          console.log(response);
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Edit Saved",
          });
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: "Edit Failed",
          });
        }
      );
    },
    setQueueScreenData(val, e) {
      let payload = {
        input: val,
        value: e.target.value,
      };
      this.$store.commit("queue/setInputData", payload);
    },
  },
  watch: {},

  computed: {
    queueScreenData() {
      return this.$store.getters["queue/inputData"];
    },
    isDisabled() {
      console.log(this.isDisabled);
      return this.$store.getters["queue/isDisabled"];
    },
    isLoading() {
      return this.$store.getters["queue/isLoading"];
    },
  },
  unmounted() {
    this.$store.commit("queue/setDisabledVenueName", true);
    this.$store.commit("queue/setDisabledCategory", true);
    this.$store.commit("queue/setDisabledMessage", true);
    this.$store.commit("queue/setDisabledReadyMessage", true);
  },
  created() {
    this.getQueueScreenData();
    // this.$store.dispatch("queue/fetchQueueInitialData").then(
    //   response => {
    //     console.log(response);
    //   },
    //   error => {
    //     console.log(error);
    //     this.$swal.fire({
    //       icon: "error",
    //       text:
    //         "Something went wrong while fetching settings for Queue Screen!",
    //       timer: 5000
    //     });
    //   }
    // );
  },
});
</script>
