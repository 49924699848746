<template>
  <div
    class="
      flex
      items-start
      justify-center
      w-full
      py-4
      x-sm:px-4
      sm:px-8
      md:px-16
      lg:px-24
      footer-modified
    "
  >
    <!-- <div>
      <svg
        class="px-2"
        xmlns="http://www.w3.org/2000/svg"
        width="83.047"
        height="21.953"
        viewBox="0 0 83.047 21.953"
      >
        <g id="Group_345" data-name="Group 345" transform="translate(0 0.5)">
          <g id="Group_8" data-name="Group 8" transform="translate(0 0)">
            <g id="Group_7" data-name="Group 7">
              <path
                id="Path_6"
                data-name="Path 6"
                d="M366,416.651l2.511-2.7-2.933-2.8a16.528,16.528,0,0,1-2.616,2.828l-2.511-2.643c2.061-1.031,3.7-2.3,3.7-4.783,0-3.409-3.066-5.418-6.237-5.418-3.2,0-6.184,2.035-6.184,5.47a5.442,5.442,0,0,0,1.427,3.462l.423.581-.475.212c-2.511,1.11-4.281,2.564-4.281,5.55,0,3.647,2.96,5.708,6.4,5.708a12.575,12.575,0,0,0,6.818-2.378l2.248,2.38,6.424-.051-4.946-5.184Zm-9.566.819a2.053,2.053,0,0,1-2.246-1.823c0-1.11,1.031-1.85,1.876-2.379l3.145,3.383A5.658,5.658,0,0,1,356.433,417.47Zm1.771-8.536c-.634-.713-1.559-1.665-1.559-2.642a1.6,1.6,0,0,1,1.665-1.639,1.751,1.751,0,0,1,1.744,1.85A3.114,3.114,0,0,1,358.2,408.934Z"
                transform="translate(-325.64 -401.138)"
                fill="#fff"
              />
              <path
                id="Path_7"
                data-name="Path 7"
                d="M269.988,411.5c0-6.2-4.609-10.231-10.56-10.231-5.5,0-10.56,4.052-10.56,9.825a10.153,10.153,0,0,0,10.56,10.534,9.868,9.868,0,0,0,5.242-1.241l1.519,1.8,5.273.007-3.931-3.982A10.392,10.392,0,0,0,269.988,411.5Zm-6.052,2.837-1.342-1.393-5.253.014,3.632,3.607a4.556,4.556,0,0,1-1.519.254,5.492,5.492,0,0,1-5.419-5.7,5.361,5.361,0,0,1,5.419-5.039,5.487,5.487,0,0,1,5.369,5.445A4.931,4.931,0,0,1,263.935,414.333Z"
                transform="translate(-248.868 -401.236)"
                fill="#fff"
              />
            </g>
          </g>
          <g id="Group_11" data-name="Group 11" transform="translate(44.372 0)">
            <path
              id="Union_1"
              data-name="Union 1"
              d="M0,10.477A10.628,10.628,0,0,1,10.774,0a10.924,10.924,0,0,1,6.3,1.979A13.437,13.437,0,0,0,14.831,5.9a5.883,5.883,0,0,0-4.058-1.634,6.115,6.115,0,0,0-6.016,6.209,6.115,6.115,0,0,0,6.016,6.209,5.886,5.886,0,0,0,4.056-1.628,6.237,6.237,0,0,0,1.713-2.82H9.619l1.031-1.863L9.619,8.512h17.36V6.838L29.1,8.607l2.125,1.768L29.1,12.143l-2.125,1.768V12.238H21.393a10.446,10.446,0,0,1-4.329,6.743,10.925,10.925,0,0,1-6.29,1.972A10.628,10.628,0,0,1,0,10.477Z"
              transform="translate(0 0)"
              fill="#009a74"
              stroke="rgba(0,0,0,0)"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <g id="Group_10" data-name="Group 10" transform="translate(17.32)">
              <path
                id="Path_9"
                data-name="Path 9"
                d="M525.446,401.138a10.7,10.7,0,0,0-10.582,8.512h4.875a6.038,6.038,0,0,1,5.706-4.244,6.212,6.212,0,0,1,0,12.418,5.886,5.886,0,0,1-4.058-1.63,13.343,13.343,0,0,1-2.239,3.921,10.924,10.924,0,0,0,6.3,1.977,10.481,10.481,0,1,0,0-20.953Z"
                transform="translate(-514.864 -401.138)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </svg>
    </div> -->
    <div class="footer-tab flex ">
      <div class="queue cursor-pointer md:w-30 lg:w-96">
        <router-link
          tag="span"
          active-class="active"
          to="/vendor/queue-console"
          exact
        >
          <a><img v-if="currentRouteName === 'VendorProfileQ'" src="@/assets/img/queue-c.png" alt="" /><img v-else src="@/assets/img/queue.png" alt="" /> Queue</a>
        </router-link>
      </div>
      <div class="console cursor-pointer md:w-30 lg:w-96 ">
        <router-link
          tag="span"
          active-class="active"
          to="/vendor/billing"
          exact
        >
          <a>
            <svg v-if="currentRouteName === 'VendorBilling'"
                class="w-5 h-5 m-2 disp"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#009a73"
              >
                <path
                  d="m23.1 10.208-1.296-.13c-.087-.449-.205-.89-.35-1.316l1.056-.759c.418-.301.54-.865.282-1.312l-.798-1.381c-.257-.446-.806-.623-1.277-.412l-1.182.534c-.299-.343-.623-.667-.966-.966l.534-1.183c.211-.47.034-1.019-.412-1.277l-1.381-.797c-.448-.258-1.011-.136-1.312.282l-.759 1.056c-.426-.146-.867-.263-1.316-.35l-.13-1.296c-.052-.514-.48-.901-.996-.901h-1.595c-.516 0-.944.387-.995.9l-.13 1.296c-.449.087-.89.205-1.316.35l-.759-1.055c-.3-.418-.864-.54-1.312-.282l-1.38.797c-.447.258-.624.807-.412 1.278l.534 1.182c-.344.299-.667.622-.966.966l-1.183-.535c-.47-.211-1.019-.034-1.277.413l-.797 1.38c-.258.448-.136 1.012.282 1.312l1.056.759c-.146.426-.263.867-.35 1.316l-1.296.13c-.514.052-.901.48-.901.996v1.595c0 .516.387.944.9.995l1.296.13c.087.449.205.89.35 1.316l-1.056.759c-.418.301-.54.865-.282 1.312l.797 1.381c.258.447.808.625 1.277.412l1.182-.534c.299.343.623.667.966.966l-.534 1.183c-.211.47-.034 1.019.412 1.277l1.381.797c.448.259 1.012.137 1.312-.282l.759-1.056c.426.146.867.263 1.316.35l.13 1.296c.053.513.481.9.997.9h1.595c.516 0 .944-.387.995-.9l.13-1.296c.449-.087.89-.205 1.316-.35l.759 1.056c.301.418.863.539 1.312.282l1.381-.797c.446-.258.624-.807.412-1.277l-.534-1.182c.343-.299.667-.623.966-.966l1.183.534c.47.211 1.02.035 1.277-.412l.797-1.381c.258-.447.136-1.011-.282-1.312l-1.056-.759c.146-.426.263-.867.35-1.316l1.296-.13c.513-.053.9-.481.9-.997v-1.595c0-.515-.387-.943-.9-.994zm-11.1-5.208c3.519 0 6.432 2.613 6.92 6h-4.632c-.387-.881-1.265-1.5-2.288-1.5-.093 0-.182.018-.273.028l-2.322-4.024c.803-.322 1.678-.504 2.595-.504zm-7 7c0-2.227 1.049-4.21 2.674-5.493l2.319 4.016c-.306.416-.493.923-.493 1.477s.187 1.061.493 1.476l-2.319 4.016c-1.625-1.283-2.674-3.265-2.674-5.492zm7 7c-.917 0-1.792-.182-2.595-.505l2.323-4.023c.09.01.179.028.272.028 1.023 0 1.901-.619 2.288-1.5h4.632c-.488 3.387-3.401 6-6.92 6z"
                />
              </svg>
              <svg v-else
                class="w-5 h-5 m-2 disp"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="#999999"
              >
                <path
                  d="m23.1 10.208-1.296-.13c-.087-.449-.205-.89-.35-1.316l1.056-.759c.418-.301.54-.865.282-1.312l-.798-1.381c-.257-.446-.806-.623-1.277-.412l-1.182.534c-.299-.343-.623-.667-.966-.966l.534-1.183c.211-.47.034-1.019-.412-1.277l-1.381-.797c-.448-.258-1.011-.136-1.312.282l-.759 1.056c-.426-.146-.867-.263-1.316-.35l-.13-1.296c-.052-.514-.48-.901-.996-.901h-1.595c-.516 0-.944.387-.995.9l-.13 1.296c-.449.087-.89.205-1.316.35l-.759-1.055c-.3-.418-.864-.54-1.312-.282l-1.38.797c-.447.258-.624.807-.412 1.278l.534 1.182c-.344.299-.667.622-.966.966l-1.183-.535c-.47-.211-1.019-.034-1.277.413l-.797 1.38c-.258.448-.136 1.012.282 1.312l1.056.759c-.146.426-.263.867-.35 1.316l-1.296.13c-.514.052-.901.48-.901.996v1.595c0 .516.387.944.9.995l1.296.13c.087.449.205.89.35 1.316l-1.056.759c-.418.301-.54.865-.282 1.312l.797 1.381c.258.447.808.625 1.277.412l1.182-.534c.299.343.623.667.966.966l-.534 1.183c-.211.47-.034 1.019.412 1.277l1.381.797c.448.259 1.012.137 1.312-.282l.759-1.056c.426.146.867.263 1.316.35l.13 1.296c.053.513.481.9.997.9h1.595c.516 0 .944-.387.995-.9l.13-1.296c.449-.087.89-.205 1.316-.35l.759 1.056c.301.418.863.539 1.312.282l1.381-.797c.446-.258.624-.807.412-1.277l-.534-1.182c.343-.299.667-.623.966-.966l1.183.534c.47.211 1.02.035 1.277-.412l.797-1.381c.258-.447.136-1.011-.282-1.312l-1.056-.759c.146-.426.263-.867.35-1.316l1.296-.13c.513-.053.9-.481.9-.997v-1.595c0-.515-.387-.943-.9-.994zm-11.1-5.208c3.519 0 6.432 2.613 6.92 6h-4.632c-.387-.881-1.265-1.5-2.288-1.5-.093 0-.182.018-.273.028l-2.322-4.024c.803-.322 1.678-.504 2.595-.504zm-7 7c0-2.227 1.049-4.21 2.674-5.493l2.319 4.016c-.306.416-.493.923-.493 1.477s.187 1.061.493 1.476l-2.319 4.016c-1.625-1.283-2.674-3.265-2.674-5.492zm7 7c-.917 0-1.792-.182-2.595-.505l2.323-4.023c.09.01.179.028.272.028 1.023 0 1.901-.619 2.288-1.5h4.632c-.488 3.387-3.401 6-6.92 6z"
                />
              </svg>
            <!-- <img v-if="currentRouteName === 'VendorBilling'" src="@/assets/img/vendor-console-c.png" alt="" />
            <img v-else src="@/assets/img/vendor-console.png" alt="" /> -->
            Vendor Console</a
          >
        </router-link>
      </div>
    </div>
    <!-- <div class="flex flex-col">
      <p class="text-xs">&copy; 2020 Q&GO. All Rights Reserved.</p>
      <p>
        <a class="px-2 text-xs underline" href="#">Terms of Service</a>
        <a class="px-2 text-xs underline" href="#">Privacy Policy</a>
      </p>
    </div> -->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { currentRoute } = useRouter();

    const activeClass = ref("text-qgo-primary-bg");
    const inactiveClass = ref("hover:text-gray-900");

    const queueBtnClass =
      currentRoute.value.name === "VendorQueues" ? activeClass : inactiveClass;
    const profileBtnClass =
      currentRoute.value.name === "VendorProfile" ? activeClass : inactiveClass;

    return {
      queueBtnClass,
      profileBtnClass,
    };
  },
  methods: {
    gotToQueue() {
      this.$router.push({ name: "VendorProfileQ" });
    },
    goToVendorConsole() {
      this.$router.push({ name: "VendorBilling" });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
});
</script>
<style lang="scss" scoped>
.footer-tab {
  div {
    font-size: 14px;
    // min-width: 500px;
    img {
      width: 30px;
      display: inline;
      margin-right: 5px;
    }
  }
  // div:hover {
  //   color: #009a74;
  // }
}
.disp {
  display: inline;
}
.active {
  color: #009a74;
}
.footer-modified {
  background-color: #eaf1ef;
  align-items: center;
  color: #999999;
  border-top: 1px solid #999999;
}
</style> 
